<template>
    <v-card>
        <v-toolbar
                dark
                color="primary"
        >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="item.id" dark text @click="save">{{ trans('buttons.common.save') }}</v-btn>
                <v-btn v-else dark text @click="save">{{ trans('buttons.common.create') }}</v-btn>
                <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text v-if="options">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                :label="trans('fields.common.label')"
                                v-model="item.label"
                                :error-messages="errors.label"
                                @input="clearError('label')"
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="!landing_design_id" cols="12">
                        <v-select
                                :items="options.landingDesigns"
                                :label="trans('fields.common.landingDesign')"
                                :multiple="false"
                                v-model="item.landing_design_id"
                                @change="landingDesignChange"
                                :error-messages="errors.landing_design_id"
                                @input="clearError('landing_design_id')"
                        ></v-select>
                    </v-col>
                    <v-col v-if="!language_id" cols="12">
                        <v-select
                                :items="options.languages"
                                :label="trans('fields.common.language')"
                                :multiple="false"
                                v-model="item.language_id"
                                :error-messages="errors.language_id"
                                @input="clearError('language_id')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <template v-for="(token) in options.landingDesignTokens">
                            <v-row v-if="token === 'userText'" :key="'data-'+token">
                                <v-col cols="12" md="6">
                                    <v-textarea
                                            v-model="item.data[token]"
                                            :label="token + ' (' + trans('designTokens.'+token) + ')'"
                                            :error-messages="errors['data.'+token]"
                                            @input="clearError('data.'+token)"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-alert
                                            outlined
                                    >
                                        <div class="title">{{ trans('pages.landingDesignData.markdown.header') }}</div>
                                        <div v-html="trans('pages.landingDesignData.markdown.text')"></div>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-text-field
                                    v-else
                                    :key="'data-'+token"
                                    v-model="item.data[token]"
                                    :label="token + ' (' + trans('designTokens.'+token) + ')'"
                                    :error-messages="errors['data.'+token]"
                                    @input="clearError('data.'+token)"
                            ></v-text-field>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "LandingDesignDataForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            landing_design_id: {
                type: [Number, undefined],
            },
            language_id: {
                type: [Number, undefined],
            }
        },
        data() {
            return {
                item: {
                    id: null,
                    label: '',
                    language_id: this.language_id,
                    landing_design_id: this.landing_design_id,
                    data: {},
                },
                showRoute: 'admin.landingDesignDatas.show',
                error: null,
                options: null,
            }
        },
        mounted() {
            this.load();
            // this.landingDesignChange();
            console.log('mounted')
        },
        methods: {
            load(){
                let id = this.itemId || this.item.id;
                if (id) {
                    axios.get(this.route(this.showRoute, id)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    this.loadOptions();
                }

            },
            loadOptions(){
                axios.post(this.route('admin.options'), {
                    languages: true,
                    landingDesigns: true,
                    landingDesignTokens: {landing_design_id: this.item.landing_design_id}
                }).then( response => {
                    this.options = response.data;

                    if (!Object.keys(this.item.data).length){
                        _.each(this.options.landingDesignTokens, (token) => {
                            this.item.data[token] = this.options.landingDesignTokensDefaults[token][this.item.language_id];
                        })
                    }
                })
            },
            languageChanged(){
                this.item.design_data_id = null;
                this.loadOptions();
            },
            landingDesignChange(){
                // let data = _.pick(this.editedItem.data, this.options.landingDesignsTokens[this.editedItem.landing_design_id]);
                // _.each(this.options.landingDesignsTokens[this.editedItem.landing_design_id], (item) => {
                //     if (!data[item]){
                //         data[item] = "";
                //     }
                // })
                // this.editedItem.data = data;
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.landingDesignDatas.store'), this.item).then((response) => {
                        console.log('save success');
                        this.item = response.data;
                        this.$emit('saved', this.item);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route('admin.landingDesignDatas.update', this.item.id), this.item).then(() => {
                        console.log('save success');
                        this.$emit('updated', this.item);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        let errors = {};
                        _.each(err.response.data.errors, (list, name) => {
                            _.set(errors, name, list);
                        });
                        this.errors = errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.landingDesignData.newDialogTitle')
                    : this.trans('pages.landingDesignData.editDialogTitle');
            },
        }
    }
</script>
